import * as React from "react";

import {Button, Grid} from "@mui/material";

const ExamMarkers = ({questions, clickHandler, isExam = false, isEndOfExam}) => {
	return (
		<Grid container direction="row" justifyContent="center" alignItems="center">
			<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
				{questions?.map((item, idx) => {
					const hasAnswer = item?.userAnswer;
					const isCorrect = item?.userAnswer?.choisedAnswerID === item?.userAnswer?.correctAnswerID;

					return (
						<Button
							key={item.name}
							size='small'
							variant={hasAnswer ? 'contained' : 'outlined'}
							color={
								isExam ?
									isEndOfExam
										? hasAnswer
											? isCorrect
												? 'success'
												: 'error'
											: 'primary'
										: hasAnswer ? 'info' : 'primary'
									:
									hasAnswer
										? isCorrect
											? 'success'
											: 'error'
										: 'primary'
							}
							sx={{
								minWidth: {xs: '30px', sm: '30px', md: '40px', lg: '50px', xl: '64px'},
								fontSize: {xs: '10px', sm: '10px', md: '13px', lg: '13px', xl: '13px'},
								margin: {xs: '0 0 20px 0', sm: '0 0 20px 0', md: '0 0 20px 0', lg: '0 0 40px 0', xl: '0 0 40px 0'}
							}}
							onClick={() => clickHandler(item.count)}
						>
							{idx + 1}
						</Button>
					)
				})}
			</div>
		</Grid>
	)
}

export default ExamMarkers;