import * as React from "react";
import {getTicket, submitTicket} from '../../api/tickets';
import {useUser} from '../../utils';
import {useLocation, useParams} from 'react-router-dom';
import {Reloaded} from '../../utils/index';

import Page from '../../components/Page';
import CustomBox from '../../components/CustomBox';
import ExamHeader from '../../components/ExamHeader';
import ExamMarkers from '../../components/ExamMarkers';
import QuestionModal from '../../components/Question';
import ResultDialog from '../../components/ResultDialog';
import QuestionsGroup from '../../components/QuestionsGroup';
import IncomingDialog from '../../components/IncomingDialogs/ticketDialog';
import {Grid, Box, CircularProgress} from "@mui/material";

const Ticket = () => {
	const {state} = useLocation();
	const {ticketId} = useParams();
	const currentCategory = useUser()?.category?.name;
	const [time, setTime] = React.useState(0);
	const [isShowIncoming, setIncomingState] = React.useState(true);
	const [timerState, setTimerState] = React.useState(false);
	const [isEnd, setIsEnd] = React.useState(false);
	const [ticket, setTicket] = React.useState([]);
	const [questions, setQuestions] = React.useState(null);
	const [currentQuestion, setCurrentQuestion] = React.useState(null);

	React.useEffect(() => {
		if (!JSON.parse(localStorage.getItem('currentExam'))) {
			getTicket(
				ticketId,
				(res) => {
					setTicket(res);
					setQuestions(res.questions.map((item, idx) => ({count: idx + 1, ...item})));
					localStorage.setItem('currentExam', res.id);
				}
			);
		}
	}, []);

	React.useEffect(() => {
		if (questions?.every(item => item.userAnswer)) {
			setIsEnd(true);
			setTimerState(false);

			const data = questions.map(item => ({
				questionId: item?.id,
				answerId: item?.userAnswer?.choisedAnswerID
			}))
			submitTicket(ticket.id, data, time, () => {});
		}
	}, [questions]);

	React.useEffect(() => {
		return () => {window.onload = () => {}}
	}, []);

	React.useEffect(() => {
		let interval;

		if (timerState) {
			interval = setInterval(() => {
				setTime(p => p + 1)
			}, 1000)
		} else {
			clearInterval(interval);
		}

		return () => {
			clearInterval(interval)
		}
	}, [timerState]);

	window.onload = function() {
		const loaded = sessionStorage.getItem('loaded');
		if(loaded) {
			Reloaded('tickets');
		} else {
			sessionStorage.setItem('loaded', true);
		}
	}

	const saveQuestionResult = (questionID, choisedAnswerID, correctAnswerID) => {
		const updatedList = questions?.map(q => {
			if (q.id === questionID) {
				return ({
					...q,
					userAnswer: {
						choisedAnswerID,
						correctAnswerID
					}
				})
			} else {
				return q
			}
		});
		setQuestions(updatedList);
	};
	
	return (
		<Page
			title="Билет"
			withoutBack
			withoutHint
			isExam={{
				path: '/tickets',
				title: 'К билетам'
			}}
		>
			<IncomingDialog
				open={isShowIncoming}
				closeHandler={() => {
					setTimerState(true);
					setIncomingState(false);
				}}
			/>
			<QuestionModal
				open={Boolean(currentQuestion)}
				closeHandler={() => setCurrentQuestion(null)}
				data={questions?.find(item => item.count === currentQuestion)}
				saveUserAnswerHandler={(q, a, r) => saveQuestionResult(q, a, r)}
				movePrevHandler={() => {
					if (currentQuestion !== 1) {
						setCurrentQuestion(p => p - 1)
					}
				}}
				moveNextHandler={() => {
					if (currentQuestion !== 20) {
						setCurrentQuestion(p => p + 1)
					}
				}}
			/>
			<ResultDialog
				open={isEnd}
				closeHandler={() => setIsEnd(false)}
				title="Билет завершён"
				total={questions?.length}
				correct={questions?.filter(item => item?.userAnswer?.choisedAnswerID === item?.userAnswer?.correctAnswerID)?.length}
				time={time}
			/>
			<CustomBox style={{ margin: '20px 0 40px 0'}}>
				<ExamHeader title={`${state?.name} (категория ${currentCategory})`} time={time}/>
				<ExamMarkers questions={questions} clickHandler={(id) => setCurrentQuestion(id)}/>
				{questions?.length
					? (
						<Grid container spacing={2}>
							<QuestionsGroup
								items={questions?.slice(0, 5)}
								onClickQuestion={(count) => setCurrentQuestion(count)}
								borderColor="green"
							/>
							<QuestionsGroup
								items={questions?.slice(5, 10)}
								onClickQuestion={(count) => setCurrentQuestion(count)}
								borderColor="purple"
							/>
							<QuestionsGroup
								items={questions?.slice(10, 15)}
								onClickQuestion={(count) => setCurrentQuestion(count)}
								borderColor="blue"
							/>
							<QuestionsGroup
								items={questions?.slice(15, 20)}
								onClickQuestion={(count) => setCurrentQuestion(count)}
								borderColor="yellow"
							/>
						</Grid>
					)
					: (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<CircularProgress />
						</Box>
					)
				}
			</CustomBox>
		</Page>
	)
}

export default Ticket;