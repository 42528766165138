import * as React from "react";
import {Link} from 'react-router-dom';
import {getCategories} from '../../api/categories';
import {useUser, useCurrentCategory} from '../../utils/index';
import {mainButtons} from '../../constants/index';

import Page from '../../components/Page';
import CustomBox from '../../components/CustomBox';
import {Button, Grid, Typography} from "@mui/material";

const Profile = () => {
	const user = useUser();
	const currentCategory = useCurrentCategory();

	React.useEffect(() => {
		if(!currentCategory) {
			getCategories((res) => {
				localStorage.setItem('whell_categories', JSON.stringify(res.rows));
				localStorage.setItem('whell_selectedCategory', JSON.stringify(res.rows[0]))
				window.location.pathname = '/';
			})
		}
	}, []);

	return (
		<Page title="Главная страница" withoutBack resetExam>
			<CustomBox style={{ margin: '20px 0 40px 0'}}>
				<Grid container>
					<Grid item xs={12}>
						<Typography
							variant="h4"
							sx={{ fontSize: {xs: '20px', sm: '20px', md: '28px', lg: '34px'}}}
						>
							Добро пожаловать, <b>{user?.name}</b>.
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: {xs: '12px', sm: '12px', md: '14px', lg: '16px'},
								margin: {xs: '0 0 20px 0', sm: '0 0 20px 0', md: '0 0 20px 0', lg: '0 0 20px 0'}
							}}
						>
							Выберите режим прохождения тестирования
						</Typography>
					</Grid>
				</Grid>

				<Grid container spacing={2}>
					{mainButtons.map(item => (
						<Grid item lg={4} md={4} sm={12} xs={12} key={item.to}>
							<Link to={item.to}>
								<Button
									variant="outlined"
									fullWidth sx={{ display: "flex", flexDirection: 'column', height: { xs: '120px', md: '120px', lg: '200px'}}}
								>
									<Typography
										variant="h5"
										style={{margin: '0 0 20px 0', fontWeight: 'bold'}}
										sx={{ fontSize: {xs: '16px', sm: '16px', md: '18px', lg: '24px'}}}
									>
										{item.title}
									</Typography>
									<Typography
										variant="caption"
										sx={{ fontSize: {xs: '12px', sm: '12px', md: '12px', lg: '14px'}}}
									>
										{item.subtitle}
									</Typography>
								</Button>
							</Link>
						</Grid>
					))}
				</Grid>
			</CustomBox>
		</Page>
	)
}

export default Profile;