import * as React from 'react';

import {
	DialogTitle,
	Dialog,
	DialogContent,
	Typography,
	Button,
} from '@mui/material';

const IncomingDialog = ({open, closeHandler}) => {

	return (
		<Dialog open={open} fullScreen onClick={closeHandler}>
			<DialogTitle
				style={{fontWeight: 'bold', textAlign: 'center'}}
				sx={{ fontSize: {xs: '16px', sm: '20px', md: '20px', lg: '20px', xl: '20px'}}}
			>
				Вы выбрали один экзаменационный билет состоящий из 20 вопросов.
			</DialogTitle>
			<DialogContent
				sx={{
					fontSize: {xs: '16px', sm: '20px', md: '20px', lg: '20px', xl: '20px'},
					fontFamily: 'Tahoma, san-serif !important'
			}}
			>
				<Typography variant="body1" style={{margin: '0 0 10px 90px'}}>Каждый вопрос имеет только 1 правильный ответ.</Typography>
				<Typography variant="body1" style={{margin: '0 0 10px 90px'}}>Для ответа выберите вопрос в произвольном порядке нажатием на соответствующий вопрос.</Typography>
				<Typography variant="body1" style={{margin: '0 0 10px 90px'}}>Выберите и нажмите на один из предложенных ответов.</Typography>
				<Typography variant="body1" style={{margin: '0 0 10px 90px'}}>Вопросы, на которые дан ответ, закрашивается серым цветом.</Typography>
				<Typography variant="body1" style={{margin: '0 0 10px 90px'}}>Около названия билета расположен секундомер, для определения использованного времени.</Typography>
				<Typography variant="body1" style={{margin: '0 0 10px 90px'}}>Для начала теста нажмите на "Перейти к билету"</Typography>
				<Button
					fullWidth
					style={{
						margin: '10px 0 0 0',
						fontSize: '16px',
						color: '#000'
					}}
				>
					<Typography variant="body1" style={{margin: '0 0 10px 0'}}>
						<b style={{ display: 'block'}}>Желаем успеха!</b>
						<br/>
						Для начала нажмите левую клавишу мыши
					</Typography>
				</Button>
			</DialogContent>
		</Dialog>
	)
};

export default IncomingDialog;