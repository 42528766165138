import axios from 'axios';
import {headers, URL} from './apiHelper';

export const getExams = (type, resolveHandler, offset = 0, limit = 100000) => {
	axios({
		method: 'get',
		headers,
		params: {
			'offset': `${offset}`,
			'limit': `${limit}`,
			'orderType': `DESC`,
			'filter[type]': `${type}`
		},
		url: `${URL}/exams/my`,
	})
	.then(res => {resolveHandler(res.data)})
};

export const getExam = (resolveHandler) => {
	axios({
		method: 'post',
		headers,
		url: `${URL}/exams`,
		data: {
			type: 'EXAM',
		},
	})
	.then(res => {resolveHandler(res.data)})
};

export const submitExam = (examID, examAnswers, examTime, resolveHandler) => {
	axios({
		method: 'post',
		headers,
		url: `${URL}/exams/${examID}`,
		data: {
			answers: examAnswers,
			time: examTime
		},
	})
	.then(res => {resolveHandler(res.data)})
};

