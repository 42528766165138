import axios from 'axios';
import {URL} from './apiHelper';


export const logIn = (user, resolveHandler, rejectHandler) => {
	axios({
		method: 'post',
		url: `${URL}/auth/login`,
		data: {
			login: user.login,
			password: user.pass
		}
	})
	.then(res => {
		localStorage.setItem('access_token', res.data?.access_token);
		localStorage.setItem('whell_user_info', JSON.stringify(res.data));
		localStorage.setItem('whell_selectedCategory', null);
		window.location.pathname = '/';
	})
	.catch((err) => {
		rejectHandler(err)
	})
};

export const logOut = () => {
	localStorage.removeItem('access_token');
	window.location.pathname = '/auth';
	localStorage.clear('whell_user_info');
	localStorage.clear('whell_categories');
};