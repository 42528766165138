import * as React from 'react';
import {Box} from "@mui/material";

const CustomBox = ({children, style, sx}) => {
	return (
		<Box style={{
			background: 'white',
			padding: '20px',
			borderRadius: '4px',
			boxShadow: '1px 2px 12px 0px rgba(34, 60, 80, 0.2)',
			...style,
		}}
		sx={sx}>
			{children}
		</Box>
	)
};

export default CustomBox;
