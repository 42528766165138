import * as React from "react";
import { Link } from "react-router-dom";
import {Button, Grid, Typography} from "@mui/material";

const ExamsList = ({items, path}) => {
	return (
		<Grid container spacing={2}>
			{items.map(item => {
				return (
					<Grid item lg={3} md={4} sm={12} xs={12} key={item.id}>
						<Link
							to={`/${path}/${item.id}`}
							state={{
								name: item.name
							}}
						>
							<Button
								variant="outlined"
								fullWidth sx={{ display: "flex", flexDirection: 'column', height: { xs: '80px', md: '80px', lg: '80px'}}}
							>
								<Typography variant="subtitle1" sx={{ fontSize: {xs: '12px', sm: '12px', md: '14px', lg: '16px'}}}>
									{item.name}
								</Typography>
							</Button>
						</Link>
					</Grid>
				)
			})}
		</Grid>
	)
}

export default ExamsList;