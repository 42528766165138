import * as React from "react";
import {RouterProvider} from "react-router-dom";
import {ThemeProvider} from '@mui/material/styles';

import router from './constants/router';
import theme from './constants/theme';

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<RouterProvider router={router}/>
		</ThemeProvider>
	);
}

export default App;
