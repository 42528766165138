import * as React from "react";
import {Helmet} from "react-helmet";
import {logIn} from "../../api/auth";

import CustomBox from "../../components/CustomBox"
import {Container, Button, TextField, Typography} from "@mui/material";

const AuthPage = () => {
	const [user, setUser] = React.useState({ login: '', pass: ''});
	const [error, setError] = React.useState(false);
	localStorage.setItem('currentExam', null);

	const changeHandler = (field, value) => {
		setUser(p => ({...p, [field]: value}))
	};

	return (
		<Container style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
			<Helmet>
				<title>Авторизация</title>
			</Helmet>
			<CustomBox style={{ width: '400px'}}>
				<Typography variant="h5" style={{margin: '0 0 20px 0', textAlign: 'center'}}>Авторизация</Typography>
				<TextField
					style={{margin: '0 0 10px 0'}}
					fullWidth
					label="Логин"
					placeholder="Введите логин"
					variant="filled"
					value={user.login}
					error={error}
					helperText={error ? "Неверные имя пользолвателя или пароль" : null}
					onChange={(e) => changeHandler('login', e.target.value)}
				/>
				<TextField
					style={{margin: '0 0 30px 0'}}
					fullWidth
					type="password"
					label="Пароль"
					placeholder="Введите пароль"
					variant="filled"
					value={user.pass}
					error={error}
					helperText={error ? "Неверные имя пользолвателя или пароль" : null}
					onChange={(e) => changeHandler('pass', e.target.value)}
				/>
				<Button
					style={{height: '56px'}}
					variant="contained"
					fullWidth
					onClick={() => logIn(user, (err) => {
						setError(err)
					})}
				>
					Войти
				</Button>
			</CustomBox>
		</Container>
	)
}

export default AuthPage;