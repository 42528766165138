import * as React from "react";
import {getExams} from "../../api/exam";
import {useUser, useCategories, useCurrentCategory} from '../../utils/index';

import Page from '../../components/Page';
import CustomBox from '../../components/CustomBox';
import List from '../../components/ExamsTable';
import {Button, Typography, Grid, ButtonGroup, Box, CircularProgress} from "@mui/material";


const Profile = () => {
	const [change, setChange] = React.useState(0);
	const user = useUser();
	const categories = useCategories();
	const currentCategory = useCurrentCategory();
	const [type, setType] = React.useState('TICKET');
	const [list, setList] = React.useState([]);

	const changeCategory = (id) => {
		const findedCategory = categories.filter(i => i.id === id);
		localStorage.setItem('whell_selectedCategory', JSON.stringify(findedCategory[0]));
		setChange(id);
	};

	React.useEffect(() => {
		getExams(type, (res) => {
			setList(res.rows);
		})
	}, [type])

	return (
		<Page title="Профиль" withoutHint backpath="/" resetExam>
			<CustomBox style={{ margin: '10px 0 0 0'}}>
				<Grid container>
					<Grid item>
						<Typography
							variant="h4"
							style={{ margin: '0 0 40px 0', fontWeight: 'bold'}}
							sx={{ fontSize: {xs: '20px', sm: '20px', md: '28px', lg: '34px'}}}
						>
							{user.name} <i style={{color: 'lightgray'}}>{`ID #${user.id}`}</i>
						</Typography>
					</Grid>
				</Grid>
				{/*<Grid*/}
				{/*	container*/}
				{/*	sx={{*/}
				{/*		flexDirection: {xs: 'column', sm: 'row', md: 'row', lg: 'row'},*/}
				{/*		alignItems: {xs: 'flex-start', sm: 'center', md: 'center', lg: 'center'}*/}
				{/*}}*/}
				{/*>*/}
				{/*	<Typography*/}
				{/*		variant="h5"*/}
				{/*		style={{ margin: '0 20px 0 0'}}*/}
				{/*		sx={{*/}
				{/*			fontSize: {xs: '16px', sm: '16px', md: '18px', lg: '24px'},*/}
				{/*			textAlign: {xs: 'center', sm: 'left', md: 'left', lg: 'left'},*/}
				{/*			width: {xs: '100%', sm: 'auto', md: 'auto', lg: 'auto'}*/}
				{/*	}}*/}
				{/*	>*/}
				{/*		Ваша категория*/}
				{/*	</Typography>*/}
				{/*	{categories.map(item => (*/}
				{/*		<Button*/}
				{/*			variant={item.id === currentCategory.id ? "contained": "outlined"}*/}
				{/*			onClick={() => changeCategory(item.id)}*/}
				{/*			sx={{*/}
				{/*				width: {xs: '100%', sm: 'auto', md: 'auto', lg: 'auto'},*/}
				{/*				margin: {xs: '10px 0 0 0', sm: '0 10px 0 0', md: '0 10px 0 0', lg: '0 10px 0 0'}*/}
				{/*			}}*/}
				{/*		>*/}
				{/*			{item.name}*/}
				{/*		</Button>*/}
				{/*	))}*/}
				{/*</Grid>*/}
			</CustomBox>
			<CustomBox style={{ margin: '10px 0 0 0'}}>
				<ButtonGroup style={{ margin: '0 0 20px 0'}}>
					<Button variant={type === 'TICKET' ? "contained" : "outlined"} onClick={() => setType('TICKET')}>Билеты</Button>
					<Button variant={type === 'TOPIC' ? "contained" : "outlined"} onClick={() => setType('TOPIC')}>Темы</Button>
					<Button variant={type === 'EXAM' ? "contained" : "outlined"} onClick={() => setType('EXAM')}>Экзамены</Button>
				</ButtonGroup>
				{list.length
					? (
						<List items={list} type={type}/>
					)
					: (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<CircularProgress />
						</Box>
					)
				}

			</CustomBox>
		</Page>
	)
}

export default Profile;