import {createTheme} from "@mui/material/styles";

const theme = createTheme({
	typography: {
		fontFamily: 'Montserrat, sans-serif',
	},
	palette: {
		type: 'light',
	}
});

export default theme;