import * as React from "react";
import {getExam, submitExam} from '../../api/exam';
import {Reloaded} from '../../utils/index';

import Page from '../../components/Page';
import CustomBox from '../../components/CustomBox';
import ExamHeader from '../../components/ExamHeader';
import ExamMarkers from '../../components/ExamMarkers';
import QuestionModal from '../../components/Question';
import ResultDialog from '../../components/ResultDialog';
import QuestionsGroup from '../../components/QuestionsGroup';
import IncomingDialog from '../../components/IncomingDialogs/ticketDialog';
import {Grid, Box, CircularProgress} from "@mui/material";

const Exam = () => {
	const [time, setTime] = React.useState(1200);
	const [isShowIncoming, setIncomingState] = React.useState(true);
	const [timerState, setTimerState] = React.useState(false);
	const [isEnd, setIsEnd] = React.useState(false);
	const [exam, setExam] = React.useState([]);
	const [questions, setQuestions] = React.useState(null);
	const [currentQuestion, setCurrentQuestion] = React.useState(null);

	const checkAnswers = () => (
		questions?.some(item => item?.userAnswer?.choisedAnswerID !== item?.userAnswer?.correctAnswerID)
	);

	React.useEffect(() => {
		if (!JSON.parse(localStorage.getItem('currentExam'))) {
			getExam(
				(res) => {
					setExam(res);
					setQuestions(res.questions.map((item, idx) => ({count: idx + 1, ...item})));
					localStorage.setItem('currentExam', res.id);
				}
			);
		}
	}, []);

	React.useEffect(() => {
		if (time === 0 || checkAnswers()) {
			setIsEnd(true);
			setTimerState(false);

			const data = questions.map(item => ({
				questionId: item?.id,
				answerId: item?.userAnswer?.choisedAnswerID
			}))
			submitExam(exam.id, data, 1200 - time, () => {});
		}
	}, [time]);

	React.useEffect(() => {
		return () => {window.onload = () => {}}
	}, []);

	React.useEffect(() => {
		let interval;

		if (timerState) {
			interval = setInterval(() => {
				setTime(p => p - 1)
			}, 1000)
		} else {
			clearInterval(interval);
		}

		return () => {
			clearInterval(interval)
		}
	}, [timerState]);

	window.onload = function() {
		const loaded = sessionStorage.getItem('loaded');
		if(loaded) {
			Reloaded('');
		} else {
			sessionStorage.setItem('loaded', true);
		}
	}

	const saveQuestionResult = (questionID, choisedAnswerID, correctAnswerID) => {
		const updatedList = questions?.map(q => {
			if (q.id === questionID) {
				return ({
					...q,
					userAnswer: {
						choisedAnswerID,
						correctAnswerID
					}
				})
			} else {
				return q
			}
		});
		setQuestions(updatedList);
		setCurrentQuestion(null);
	};
	
	return (
		<Page
			title="Экзамен"
			withoutBack
			withoutHint
			isExam={{
				path: '/',
				title: 'На главную'
			}}
		>
			<IncomingDialog
				open={isShowIncoming}
				closeHandler={() => {
					setTimerState(true);
					setIncomingState(false);
				}}
			/>
			<QuestionModal
				isExam
				isEndOfExam={!timerState}
				open={Boolean(currentQuestion)}
				closeHandler={() => setCurrentQuestion(null)}
				data={questions?.find(item => item.count === currentQuestion)}
				saveUserAnswerHandler={(q, a, r) => saveQuestionResult(q, a, r)}
				movePrevHandler={() => {
					if (currentQuestion !== 1) {
						setCurrentQuestion(p => p - 1)
					}
				}}
				moveNextHandler={() => {
					if (currentQuestion !== 20) {
						setCurrentQuestion(p => p + 1)
					}
				}}
			/>
			<ResultDialog
				isExam
				isError={checkAnswers()}
				open={isEnd}
				closeHandler={() => setIsEnd(false)}
				title={checkAnswers() ? 'Экзамен провален' : 'Экзамен пройден'}
				total={questions?.length}
				correct={questions?.filter(item => item?.userAnswer && item?.userAnswer?.choisedAnswerID === item?.userAnswer?.correctAnswerID)?.length}
				time={1200 - time}
			/>
			<CustomBox style={{ margin: '20px 0 40px 0'}}>
				<ExamHeader title="Экзамен" time={time}/>
				<ExamMarkers
					questions={questions}
					clickHandler={(id) => setCurrentQuestion(id)}
					isEndOfExam={!timerState}
					isExam
				/>
				{questions?.length
					? (
						<Grid container spacing={2}>
							<QuestionsGroup
								isEndOfExam={!timerState}
								isExam
								items={questions?.slice(0, 5)}
								onClickQuestion={(count) => setCurrentQuestion(count)}
								borderColor="green"
							/>
							<QuestionsGroup
								isEndOfExam={!timerState}
								isExam
								items={questions?.slice(5, 10)}
								onClickQuestion={(id) => setCurrentQuestion(id)}
								borderColor="purple"
							/>
							<QuestionsGroup
								isEndOfExam={!timerState}
								isExam
								items={questions?.slice(10, 15)}
								onClickQuestion={(id) => setCurrentQuestion(id)}
								borderColor="blue"
							/>
							<QuestionsGroup
								isEndOfExam={!timerState}
								isExam
								items={questions?.slice(15, 20)}
								onClickQuestion={(id) => setCurrentQuestion(id)}
								borderColor="yellow"
							/>
						</Grid>
					)
					: (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<CircularProgress />
						</Box>
					)
				}

			</CustomBox>
		</Page>
	)
}

export default Exam;