import * as React from 'react';
import {Link} from 'react-router-dom';
import {useUser} from '../../utils';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import {logOut} from "../../api/auth";

const Header = ({isExam = null, examBackHandler}) => {
	const user = useUser();
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	return (
		<AppBar position="static">
			<Container maxWidth="1900">
				<Toolbar disableGutters>
					{isExam
						? (
							<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', lg: 'flex' } }}>
								<Link to={isExam?.path} onClick={examBackHandler}>
									<Typography textAlign="center">{`< ${isExam?.title}`}</Typography>
								</Link>
							</Box>
						)
						: (
							<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', lg: 'flex' } }}>
								<IconButton
									size="large"
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={handleOpenNavMenu}
									color="inherit"
								>
									<MenuIcon />
								</IconButton>
								<Menu
									id="menu-appbar"
									anchorEl={anchorElNav}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									open={Boolean(anchorElNav)}
									onClose={handleCloseNavMenu}
									sx={{
										display: { xs: 'block', md: 'block', lg: 'block' },
									}}
								>
									<Link to="/">
										<MenuItem onClick={handleCloseNavMenu}>
											<Typography textAlign="center">Главная</Typography>
										</MenuItem>
									</Link>
									<Link to="/themes">
										<MenuItem onClick={handleCloseNavMenu}>
											<Typography textAlign="center">Темы</Typography>
										</MenuItem>
									</Link>
									<Link to="/tickets">
										<MenuItem onClick={handleCloseNavMenu}>
											<Typography textAlign="center">Билеты</Typography>
										</MenuItem>
									</Link>
									<Link to="/exam">
										<MenuItem onClick={handleCloseNavMenu}>
											<Typography textAlign="center">Экзамен</Typography>
										</MenuItem>
									</Link>
								</Menu>
							</Box>
						)
					}
					<Box sx={{ flexGrow: 0 }}>
						<Tooltip title="Открыть меню">
							<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
								<Avatar alt="У" src="" />
							</IconButton>
						</Tooltip>
						{!isExam && (
							<Menu
								sx={{ mt: '45px' }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
							>
								<MenuItem onClick={handleCloseUserMenu}>
									<Link to="/profile">
										<Typography textAlign="center">Профиль</Typography>
									</Link>
								</MenuItem>
								{user?.role === 'ADMIN' && (
									<MenuItem onClick={handleCloseUserMenu}>
										<Link to="https://admin.cpvptz.ru" reloadDocument>
											<Typography textAlign="center">Админка</Typography>
										</Link>
									</MenuItem>
								)}
								<MenuItem onClick={() => logOut()}>
									<Typography textAlign="center">Выйти</Typography>
								</MenuItem>
							</Menu>
						)}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
};
export default Header;
