export const mainButtons = [
	{
		to: '/themes',
		title: 'Темы',
		subtitle: 'Вопросы из ПДД сгрупированные по темам'
	},
	{
		to: '/tickets',
		title: 'Билеты',
		subtitle: '20 вопросов из 4 случайных тем'
	},
	{
		to: '/exam',
		title: 'Экзамен',
		subtitle: 'Аналог экзамена сдаваемого в ГАИ'
	},
];