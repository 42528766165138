import * as React from 'react';
import {Link} from 'react-router-dom'

import {
	DialogTitle,
	Dialog,
	DialogContent,
	Typography,
	Button,
	Grid
} from '@mui/material';

const ResultDialog = ({open, closeHandler, title, total, correct, time, isExam = false, isError}) => {
	const getFormatTime = (time) => time.toString().padStart(2, '0')
	const min = getFormatTime(Math.floor(time / 60));
	const sec = getFormatTime(time - min * 60);

	return (
		<Dialog open={open}>
			<DialogTitle
				style={{fontWeight: 'bold', textAlign: 'center'}}
				sx={{ fontSize: {xs: '16px', sm: '20px', md: '20px', lg: '20px', xl: '20px'}}}
			>
				<span style={{ color: isExam && isError ? '#e57373' : '#81c784' }}>{title}</span>
			</DialogTitle>
			<DialogContent
				sx={{ width: {xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto'}}}
			>
				{time && (
					<Typography variant="body2" style={{fontWeight: 'bold', margin: '0 0 5px 0'}}>Времени потрачено - {min}:{sec}</Typography>
				)}
				<Typography variant="body2" style={{fontWeight: 'bold', margin: '0 0 5px 0'}}>Всего вопросов - {total}</Typography>
				<Typography variant="body2" style={{fontWeight: 'bold', margin: '0 0 5px 0'}}>Верных ответов - {correct}</Typography>
				{!isExam && (
					<Typography variant="body2" style={{fontWeight: 'bold', margin: '0 0 5px 0'}}>Неверных ответов - {total - correct}</Typography>
				)}


				<Typography variant="body2" style={{margin: '20px 0 5px 0'}}>Для просмотра списка вопросов нажмите на "Вернуться к вопросам"</Typography>
				<Typography variant="body2" style={{margin: '0 0 10px 0'}}>Для возврата к главному меня нажмите на "Перейти в меню"</Typography>
				<Grid container spacing={2}>
					<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
						<Button
							variant='contained'
							fullWidth
							style={{ margin: '10px 0 0 0'}}
							onClick={() => {
								closeHandler();
							}}
						>
							Вернуться к вопросам
						</Button>
					</Grid>
					<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
						<Link to="/">
							<Button
								variant='contained'
								fullWidth
								style={{ margin: '10px 0 0 0'}}
							>
								На главную
							</Button>
						</Link>
					</Grid>
				</Grid>

			</DialogContent>
		</Dialog>
	)
};

export default ResultDialog;