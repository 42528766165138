import * as React from "react";
import {getTopics} from '../../api/topics';

import Page from '../../components/Page';
import ExamsList from '../../components/ExamsList';
import CustomBox from '../../components/CustomBox';

import {Grid, Typography, Box, CircularProgress} from "@mui/material";

const Themes = () => {
	const [topics, setTopics] = React.useState([]);

	React.useEffect(() => {
		getTopics((res) => {
			setTopics(res.rows);
		})
	}, []);

	return (
		<Page title="Темы" backpath="/" resetExam>
			<CustomBox style={{ margin: '20px 0 40px 0'}}>
				<Grid container>
					<Grid item xs={12}>
						<Typography
							variant="h4"
							style={{fontWeight: 'bold'}}
							sx={{ fontSize: {xs: '20px', sm: '20px', md: '28px', lg: '34px'}}}
						>
							Темы
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{
								fontSize: {xs: '12px', sm: '12px', md: '14px', lg: '16px'},
								margin: {xs: '0 0 20px 0', sm: '0 0 20px 0', md: '0 0 20px 0', lg: '0 0 20px 0'}
						}}
						>
							Для начала прохождения теста, выберите тему.
						</Typography>
					</Grid>
				</Grid>
				{topics?.length
					? (<ExamsList items={topics} path='themes'/>)
					: (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<CircularProgress />
						</Box>
					)
				}
			</CustomBox>
		</Page>
	)
}

export default Themes;