import * as React from 'react';
import {Modal, Typography, Grid, Button} from '@mui/material';
import CustomBox from "../CustomBox";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	padding: 0,
	width: '100%',
	height: '100%',
};

const content = {
	width: '100%',
	maxWidth: '980px',
	margin: '0 auto',
}

const content_btn = {
	width: '100%',
	maxWidth: '980px',
	margin: '45px auto 0',
	display: 'flex',
	justifyContent: 'space-between'
}

const QuestionModal = ({
	open,
	closeHandler,
	data,
	saveUserAnswerHandler,
	movePrevHandler,
	moveNextHandler,
	isExam = false,
	isEndOfExam
}) => {
	const [answer, setAnswer] = React.useState(null);
	const correctAnswerID = data?.answers.filter(i => i.isRight);

	const answerHandler = () => {
		if (answer) {
			if (isExam) {
				if (!isEndOfExam) {
					if (!data?.userAnswer && saveUserAnswerHandler) {
						saveUserAnswerHandler(data.id, answer?.id, correctAnswerID[0].id);
						setAnswer(null);
						closeHandler();
					}
				}
			} else {
				if (!data?.userAnswer && saveUserAnswerHandler) {
					saveUserAnswerHandler(data.id, answer?.id, correctAnswerID[0].id);
					setAnswer(null);
					closeHandler();
				}
			}
		}
	};

	const goNext = () => {
		moveNextHandler();
		setAnswer(null);
	};

	const goPrev = () => {
		movePrevHandler();
		setAnswer(null);
	};

	React.useEffect(() => {
		const listner = (e) => {
			if (e.code === 'Escape') {
				closeHandler();
			}

			if (e.code === 'Space') {
				answerHandler();
			}

			if (e.code === 'ArrowRight') {
				goNext();
			}

			if (e.code === 'ArrowLeft') {
				goPrev();
			}
		};

		if (open) {
			document.addEventListener('keydown', listner, false);
		}

		return () => {
			document.removeEventListener('keydown',listner, false);
		}
	}, [open, answerHandler, answer]);

	return (
		<Modal
			open={open}
			autoFocus
		>
			<CustomBox
				style={style}
				sx={{
					width: {xs: '94%', sm: '94%', md: '88%', lg: '70%', xl: '70%'},
					height: {xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto'},
					minHeight: {xs: '80%', sm: '70%', md: '80%', lg: '70%', xl: '70%'},
				}}
			>
				<div id="question">
					<div style={content}>
						<div style={{height: '377px'}}>
							{data?.image && (
								<img
									src={data.image}
									style={{display: 'block', width: '100%', margin: '44px auto 10px'}}
									alt=""
								/>
							)}
						</div>
						<Typography
							variant="h4"
							style={{fontWeight: 'bold', textAlign: 'center'}}
							sx={{
								margin: {xs: '0 0 10px 0', sm: '0 0 10px 0', md: '0 0 10px 0', lg: '0 0 10px 0', xl: '0 0 10px 0'},
								fontFamily: {xs: 'Tahoma, san-serif'},
								fontSize: {xs: '20px'}
							}}
						>
							{data?.name}
						</Typography>
					</div>
					<div style={{width: '100%', height: '2px', background: '#000'}}/>
					<div style={content}>
						<Grid container>
							{data?.answers.map(item => {
								const hasAnswer = data?.userAnswer;
								const isSelectedItem = item.id === data?.userAnswer?.choisedAnswerID;
								const isCorrectItem = item.id === data?.userAnswer?.correctAnswerID;
								const isSelectedOrCorrectItem = item.id === data?.userAnswer?.correctAnswerID ||
									item.id === data?.userAnswer?.choisedAnswerID;

								if (item.text) {
									return (
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Button
												key={item.id}
												variant={
													item.id === answer?.id
														? 'contained'
														: isExam
															? isEndOfExam
																? hasAnswer
																	? isSelectedOrCorrectItem ? "contained" : "outlined"
																	: "outlined"
																: isSelectedItem
																	? "contained"
																	: "outlined"
															: hasAnswer
																? isSelectedOrCorrectItem
																	? "contained"
																	: "outlined"
																: "outlined"
												}
												fullWidth
												sx={{
													borderRadius: 0,
													border: 'none',
													borderBottom: '2px solid #000',
													fontFamily: 'Tahoma, san-serif',
													textTransform: 'none',
													padding: 0,
													minHeight: {xs: 'auto', sm: 'auto', md: '66px', lg: '66px', xl: '66px'},
													fontSize: {xs: '10px', sm: '18px', md: '18px', lg: '18px', xl: '18px'},
													justifyContent: {xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'flex-start', xl: 'flex-start'},
													color: {
														xs: 'black'
													},
													textAlign: 'left',
													background: item.id === answer?.id
														? '#00b5ff'
														: isExam
															? isEndOfExam
																? hasAnswer
																	? isSelectedOrCorrectItem ? "#00b5ff" : "#fff"
																	: "#fff"
																: isSelectedItem
																	? "#00b5ff"
																	: "#fff"
															: hasAnswer
																? isSelectedOrCorrectItem
																	? "#00b5ff"
																	: "#fff"
																: "#fff",
													background: isExam ?
														isEndOfExam
															?
															hasAnswer
																? isCorrectItem
																	? "success"
																	: "primary"
																: "primary"
															: "primary"
														: hasAnswer
															? isCorrectItem
																? "success"
																: "primary"
															: "primary",
													'&:hover': {
														background: '#89ddff',
														border: 'none',
														borderBottom: '2px solid #000',
													}
												}}
												onClick={() => {
													if (!hasAnswer) {
														setAnswer({id: item.id, isRight: item.isRight})
													}
												}}
												color={
													isExam ?
														isEndOfExam
															?
															hasAnswer
																? isCorrectItem
																	? "success"
																	: "primary"
																: "primary"
															: "primary"
														: hasAnswer
															? isCorrectItem
																? "success"
																: "primary"
															: "primary"
												}
											>
												{item.text}
											</Button>
										</Grid>
									)
								}
							})}
						</Grid>
					</div>
					<div style={content}>
						{(data?.userAnswer && data?.userAnswer?.correctAnswerID !== data?.userAnswer?.choisedAnswerID) && (
							<span style={{
								display: 'block',
								padding: '12px',
								fontFamily: 'Tahoma, san-serif',
								fontSize: '16px'
							}}>{data.text}</span>
						)}
					</div>
					<div style={content_btn}>
						<div>
							<Button
								onClick={() => {answerHandler()}}
								disabled={!answer}
								variant='contained'
								sx={{
									width: '127px',
									height: '60px',
									fontFamily: 'Tahoma, san-serif',
									fontSize: '18px',
									lineHeight: 'normal',
									color: '#000',
									textTransform: 'none',
									background: '#00b5ff',
									'&:hover': {
										background: '#00b5ff',
									}
								}}
							>
								Ответить <br/> (Пробел)
							</Button>
						</div>
						<div>
							<Button
								fullWidth
								onClick={() => {movePrevHandler()}}
								variant='contained'
								sx={{
									width: '177px',
									height: '60px',
									fontFamily: 'Tahoma, san-serif',
									fontSize: '18px',
									color: '#000',
									textTransform: 'none',
									lineHeight: 'normal',
									background: '#00b5ff',
									'&:hover': {
										background: '#00b5ff',
									}
								}}
							>
								К предыдущему<br/>⇐
							</Button>

							<Button
								fullWidth
								onClick={ () => {
									setAnswer(null);
									closeHandler();
								}}
								variant='contained'
								sx={{
									width: '139px',
									height: '60px',
									fontFamily: 'Tahoma, san-serif',
									fontSize: '18px',
									color: '#000',
									textTransform: 'none',
									lineHeight: 'normal',
									background: '#00b5ff',
									margin: '0 15px 0',
									'&:hover': {
										background: '#00b5ff',
									}
								}}
							>
								К перечню <br/> (Ecs)
							</Button>

							<Button
								fullWidth
								onClick={() => {moveNextHandler()}}
								variant='contained'
								sx={{
									width: '167px',
									height: '60px',
									fontFamily: 'Tahoma, san-serif',
									fontSize: '18px',
									color: '#000',
									textTransform: 'none',
									lineHeight: 'normal',
									background: '#00b5ff',
									'&:hover': {
										background: '#00b5ff',
									}
								}}
							>
								К следующему<br/>⇒
							</Button>
						</div>
					</div>
				</div>
			</CustomBox>
		</Modal>
	)
};

export default QuestionModal;