export const useUser = () => {
	const info = localStorage.getItem('whell_user_info');
	return JSON.parse(info);
};

export const useCurrentCategory = () => {
	const categories = localStorage.getItem('whell_selectedCategory');
	return JSON.parse(categories);
};

export const useCategories = () => {
	const categories = localStorage.getItem('whell_categories');
	return JSON.parse(categories);
};

export const getFormatTime = (time) => time.toString().padStart(2, '0');

export const Reloaded = (path) => {
	window.location.pathname = `/${path}`;
	localStorage.setItem('currentExam', null);
};
