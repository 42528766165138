import {createBrowserRouter} from "react-router-dom";
import AuthPage from "../pages/Auth";
import Profile from "../pages/Profile";
import Main from "../pages/Main";
import Themes from "../pages/Themes";
import Theme from "../pages/Theme";
import Tickets from "../pages/Tickets";
import Ticket from "../pages/Ticket";
import Exam from "../pages/Exam";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Main/>,
		exact: true
	},
	{
		path: "/profile",
		element: <Profile/>,
		exact: true
	},
	{
		path: "/auth",
		element: <AuthPage/>,
	},
	{
		path: "/themes",
		element: <Themes/>,
	},
	{
		path: "/themes/:themeId",
		element: <Theme/>,
	},
	{
		path: "/tickets",
		element: <Tickets/>,
	},
	{
		path: "/tickets/:ticketId",
		element: <Ticket/>,
	},
	{
		path: "/exam",
		element: <Exam />,
	},
]);

export default router;