import * as React from "react";
import {Helmet} from "react-helmet";
import { Navigate, Link } from "react-router-dom";
import CustomBox from '../../components/CustomBox';
import {useCurrentCategory} from '../../utils';

import {Container, Typography, Button} from '@mui/material';
import Header from '../Header'
import Rollback from '../RollBack'

const Page = ({
	children,
	title,
	withoutHint = false,
	withoutBack = false,
	backpath,
	resetExam = false,
	isExam = null,
	examBackHandler
}) => {
	const isAuth = localStorage.getItem('access_token');
	const currentCategory = useCurrentCategory();

	if (resetExam) {
		localStorage.setItem('currentExam', null);
	}

	if (!isAuth) {
		return <Navigate to="/auth" replace />
	}

	return (
		<>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<Header isExam={isExam} examBackHandler={examBackHandler}/>
			<Container maxWidth="1900">
				{!withoutBack && (
					<Rollback title="Назад" to={backpath}/>
				)}
				{/*{!withoutHint && (*/}
				{/*	<CustomBox style={{margin: '10px 0 0 0'}} sx={{ display: 'flex', alignItems: 'center' }}>*/}
				{/*		<Typography sx={{ fontSize: {xs: '12px', sm: '16px', md: '16px', lg: '16px'}}}>*/}
				{/*			Текущая категория <b>{currentCategory?.name}</b>.*/}
				{/*		</Typography>*/}
				{/*		<Link to="/profile">*/}
				{/*			<Button*/}
				{/*				variant="contained"*/}
				{/*				style={{ margin: '0 0 0 20px'}}*/}
				{/*				sx={{ fontSize: {xs: '12px', sm: '16px', md: '16px', lg: '16px'}}}*/}
				{/*			>Сменить категорию</Button>*/}
				{/*		</Link>*/}
				{/*	</CustomBox>*/}
				{/*)}*/}
				{children}
			</Container>
		</>
	)
}

export default Page;