import * as React from "react";
import { v4 as uuid } from 'uuid';
import {getFormatTime} from "../../utils";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {Link} from "react-router-dom";

const List = ({ items, type }) => {
	const statusBuilder = (q, a) => {
		switch (type) {
			case 'EXAM':
				return (
					q.length !== a?.length
						? 'Не завершен'
						: a.some(i => !i.isRight) ? 'Не пройден' : 'Пройден'
				);
			case 'TICKET':
				return (
					q.length !== a?.length
						? 'Не завершен'
						: a.some(i => !i.isRight) ? 'Не пройден' : 'Пройден'
				);
			case 'TOPIC':
				return (
					q.length !== a?.length
						? 'Не завершен'
						: a.some(i => !i.isRight) ? 'Не пройден' : 'Пройден'
				);
			default:
				return 'Статус не известен'
		}
	};

	const answerBuilder = (q, a) => {
		switch (type) {
			case 'EXAM':
				return (`${(a || []).filter(a => a.isRight).length} / ${q.length}`);
			case 'TICKET':
				return (`${(a || []).filter(a => a.isRight).length} / ${q.length}`);
			case 'TOPIC':
				return (`${(a || []).filter(a => a.isRight).length} / ${q.length}`);
			default:
				return 'Информации нет'
		}
	};

	const titleBuilder = (name, status, id) => {
		if (type === 'TOPIC') {
			return (
				<TableCell component="th" scope="row">
					{status === 'Не завершен' ? (
						<Link
							to={`/continue/${id}`}
							style={{textDecoration: 'underline'}}
						>
							{name}
						</Link>
					) : (
						name
					)}
				</TableCell>
			);
		} else {
			return (
				<TableCell component="th" scope="row">
					{name}
				</TableCell>
			);
		}
	};

	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Название</TableCell>
						{type !== 'TOPIC' && (
							<TableCell>Время</TableCell>
						)}
						<TableCell>Статистика</TableCell>
						<TableCell>Статус</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((item) => {
						const status = statusBuilder(item.questions, item.answers);
						const answers = answerBuilder(item.questions, item.answers);
						const min = item.time ? getFormatTime(Math.floor(item?.time / 60)) : '00';
						const sec = item.time ? getFormatTime(item?.time - min * 60) : '00';

						return (
							<TableRow
								key={uuid()}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								{titleBuilder(item?.entity?.name || "Экзамен", status, item.id)}
								{type !== 'TOPIC' && (
									<TableCell component="th" scope="row">
										{`${min}:${sec}`}
									</TableCell>
								)}
								<TableCell component="th" scope="row">
									{answers}
								</TableCell>
								<TableCell component="th" scope="row">
									{status}
								</TableCell>
							</TableRow>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default List;