import * as React from "react";
import {getTopic, submitTopic} from '../../api/topics';
import {useLocation} from 'react-router-dom';
import {Reloaded} from '../../utils/index';

import Page from '../../components/Page';
import CustomBox from '../../components/CustomBox';
import ExamHeader from '../../components/ExamHeader';
import ExamMarkers from '../../components/ExamMarkers';
import QuestionModal from '../../components/Question';
import ResultDialog from '../../components/ResultDialog';
import QuestionsGroup from '../../components/QuestionsGroup';
import IncomingDialog from '../../components/IncomingDialogs/themesDialog';

import {Grid, Box, CircularProgress} from "@mui/material";
import {useParams} from "react-router-dom";

const Theme = () => {
	const {state} = useLocation();
	const { themeId } = useParams();
	const [isShowIncoming, setIncomingState] = React.useState(true);
	const [isEnd, setIsEnd] = React.useState(false);
	const [topic, setTopic] = React.useState([]);
	const [questions, setQuestions] = React.useState(null);
	const [currentQuestion, setCurrentQuestion] = React.useState(null);

	React.useEffect(() => {
		if (!JSON.parse(localStorage.getItem('currentExam'))) {
			getTopic(
				themeId,
				(res) => {
					setTopic(res);
					setQuestions(res.questions.map((item, idx) => ({count: idx + 1, ...item})));
					localStorage.setItem('currentExam', res.id);
				}
			);
		}
		return () => {
			window.onload = () => {}
		}
	}, []);

	React.useEffect(() => {
		if (questions?.every(item => item.userAnswer)) {
			setIsEnd(true);

			const data = questions.map(item => ({
				questionId: item?.id,
				answerId: item?.userAnswer?.choisedAnswerID
			}))
			submitTopic(topic.id, data, 0, () => {});
		}
	}, [questions]);

	window.onload = function() {
		const loaded = sessionStorage.getItem('loaded');
		if(loaded) {
			Reloaded('themes');
		} else {
			sessionStorage.setItem('loaded', true);
		}
	}

	const saveQuestionResult = (questionID, choisedAnswerID, correctAnswerID) => {
		const updatedList = questions?.map(q => {
			if (q.id === questionID) {
				return ({
					...q,
					userAnswer: {
						choisedAnswerID,
						correctAnswerID
					}
				})
			} else {
				return q
			}
		});
		setQuestions(updatedList);
	};

	return (
		<Page
			title="Темы"
			withoutBack
			withoutHint
			isExam={{
				path: '/themes',
				title: 'К темам'
			}}
			examBackHandler={() => {
				const data = questions.filter(item => item.userAnswer).map(item => ({
					questionId: item?.id,
					answerId: item?.userAnswer?.choisedAnswerID
				}))
				submitTopic(topic.id, data, 0, () => {});
			}}
		>
			<IncomingDialog
				type="topic"
				open={isShowIncoming}
				closeHandler={() => {
					setIncomingState(false);
				}}
			/>
			<QuestionModal
				open={Boolean(currentQuestion)}
				closeHandler={() => setCurrentQuestion(null)}
				data={questions?.find(item => item.count === currentQuestion)}
				saveUserAnswerHandler={(q, a, r) => saveQuestionResult(q, a, r)}
				movePrevHandler={() => {
					if (currentQuestion !== 1) {
						setCurrentQuestion(p => p - 1)
					}
				}}
				moveNextHandler={() => {
					if (currentQuestion !== questions.length) {
						setCurrentQuestion(p => p + 1)
					}
				}}
			/>
			<ResultDialog
				open={isEnd}
				closeHandler={() => setIsEnd(false)}
				title="Тема завершена"
				total={questions?.length}
				correct={questions?.filter(item => item?.userAnswer?.choisedAnswerID === item?.userAnswer?.correctAnswerID)?.length}
			/>
			<CustomBox style={{ margin: '20px 0 40px 0'}}>
				<ExamHeader title={state?.name} withoutTime/>
				<ExamMarkers questions={questions} clickHandler={(id) => setCurrentQuestion(id)}/>
				<Grid container spacing={2}>
					{questions?.length
						? (
							<QuestionsGroup
								xl={12} lg={12} md={12} sm={12} xs={12}
								isTopic
								items={questions}
								onClickQuestion={(count) => setCurrentQuestion(count)}
							/>
						)
						: (
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<CircularProgress />
							</Box>
						)
					}
				</Grid>
			</CustomBox>
		</Page>
	)
}

export default Theme;