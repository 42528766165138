import axios from 'axios';
import {headers, URL} from './apiHelper';

export const getTopics = (resolveHandler, offset = 0, limit = 100) => {
	axios({
		method: 'get',
		headers,
		params: {
			'offset': `${offset}`,
			'limit': `${limit}`,
		},
		url: `${URL}/topics`,
	})
	.then(res => {resolveHandler(res.data)})
};

export const getTopic = (id, resolveHandler) => {
	axios({
		method: 'post',
		headers,
		url: `${URL}/exams`,
		data: {
			type: 'TOPIC',
			entityId: id
		},
	})
	.then(res => {resolveHandler(res.data)})
};

export const submitTopic = (examID, examAnswers, examTime, resolveHandler) => {
	axios({
		method: 'post',
		headers,
		url: `${URL}/exams/${examID}`,
		data: {
			answers: examAnswers,
			time: examTime
		},
	})
	.then(res => {resolveHandler(res.data)})
};

