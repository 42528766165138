import axios from 'axios';
import {URL, headers} from './apiHelper';

export const getCategories = (resolveHandler) => {
	axios({
		method: 'get',
		headers,
		url: `${URL}/categories`,
	})
	.then(res => {resolveHandler(res.data)})
};
