import * as React from "react";
import {getFormatTime} from '../../utils/index';

import {Grid, Typography} from "@mui/material";

const ExamHeader = ({title, time, withoutTime = false}) => {
	const min = getFormatTime(Math.floor(time / 60));
	const sec = getFormatTime(time - min * 60);

	return (
		<Grid container>
			<Grid item xl={11} lg={11} md={11} sm={11} xs={12}>
				<Typography
					variant="h4"
					style={{fontWeight: 'bold'}}
					sx={{
						fontSize: {xs: '20px', sm: '20px', md: '28px', lg: '34px', xl: '34px'},
						margin: {xs: '0 0 20px 0', sm: '0 0 20px 0', md: '0 0 20px 0', lg: '0 0 40px 0', xl: '0 0 40px 0'},
						display: {xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex'},
						justifyContent: {xs: 'center', sm: 'space-between', md: 'space-between', lg: 'space-between', xl: 'space-between'},
					}}
				>
					{title}
				</Typography>
			</Grid>
			<Grid item xl={1} lg={1} md={1} sm={1} xs={12}>
				{!withoutTime && (
					<Typography
						variant="h4"
						style={{
							fontWeight: 'bold',
						}}
						sx={{
							fontSize: {xs: '20px', sm: '20px', md: '28px', lg: '34px', xl: '34px'},
							margin: {xs: '0 0 20px 0', sm: '0 0 20px 0', md: '0 0 20px 0', lg: '0 0 40px 0', xl: '0 0 40px 0'},
							display: {xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'flex'},
							justifyContent: {xs: 'center', sm: 'flex-end', md: 'flex-end', lg: 'flex-end', xl: 'flex-end'},
						}}
					>
						{min}:{sec}
					</Typography>
				)}
			</Grid>
		</Grid>
	)
}

export default ExamHeader;