import * as React from 'react';

import {
	DialogTitle,
	Dialog,
	DialogContent,
	Typography,
	Button,
} from '@mui/material';

const IncomingDialog = ({open, closeHandler}) => {

	return (
		<Dialog open={open}>
			<DialogTitle
				style={{fontWeight: 'bold',textAlign: 'center'}}
				sx={{ fontSize: {xs: '16px', sm: '20px', md: '20px', lg: '20px', xl: '20px'}}}
			>
				Вы выбрали тест по теме, состоящей из нескольких вопросов.
			</DialogTitle>
			<DialogContent
				sx={{ width: {xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto'}}}
			>
				<Typography variant="body2" style={{margin: '0 0 10px 0'}}>Тест помогает в осмыслении, понимании ПДД и выяснении Вашего уровня знаний.</Typography>
				<Typography variant="body2" style={{margin: '0 0 10px 0'}}>На каждый вопрос приведено несколько ответов, один из которых правильный.</Typography>
				<Typography variant="body2" style={{margin: '0 0 10px 0'}}>На каждый вопрос приведено несколько ответов, один из которых правильный.</Typography>
				<Typography variant="body2" style={{margin: '0 0 10px 0'}}>Для ответа выберите вопрос в произвольном порядке нажатием на соответствующий вопрос.</Typography>
				<Typography variant="body2" style={{margin: '0 0 10px 0'}}>Выберите и нажмите на один из предложенных ответов.</Typography>
				<Typography variant="body2" style={{margin: '0 0 10px 0'}}>Вопросы, на которые дан ответ, закрашивается серым цветом.</Typography>
				<Typography variant="body2" style={{margin: '0 0 10px 0'}}>Около названия темы расположен секундомер, для определения использованного времени.</Typography>
				<Typography variant="body2" style={{margin: '0 0 10px 0'}}>Для начала теста нажмите на "Перейти к теме"</Typography>
				<Button
					variant='contained'
					fullWidth
					style={{ margin: '10px 0 0 0'}}
					onClick={closeHandler}
				>
					Перейти к теме
				</Button>
			</DialogContent>
		</Dialog>
	)
};

export default IncomingDialog;